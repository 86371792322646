
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






































































































.mobile-network__hero {
  ::v-deep {
    .hero__slides__item__title {
      @extend %fw-medium;

      strong {
        @extend %fw-black;

        color: $white;
      }
    }
  }
}

.mobile-network__speed-section__picture {
  position: relative;

  img {
    position: relative;
    z-index: 10;
    display: block;
  }
}

.mobile-network__speed-section__speedlines {
  width: 100%;
}

.mobile-network__map {
  max-width: 85rem;
  margin: 0 auto;
  text-align: center;
}

.mobile-network__map__title {
  margin: 0 0 $spacing * 2;
}
