
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




















































































































































































































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.mobile-network-comparatif {
  position: relative;
}

.mobile-network-comparatif-inner {
  .card-small__title {
    margin: 0 0 $spacing * 2.5;
    text-align: center;

    @include mq(m) {
      margin: 0 0 $spacing * 2;
    }
  }

  .card-small__description {
    width: col(8);
    margin: $spacing * 0.75 auto $spacing * 1.5;
    font-size: 1.8rem;
    line-height: 1.5;
    text-align: center;

    @include mq(m) {
      font-size: 2rem;
    }

    @include mq(l) {
      margin-bottom: $spacing * 2.5;
      font-size: 2.4rem;
    }
  }

  .slidy-outer {
    position: relative;
  }

  .tooltip {
    position: absolute;
    top: 9rem;
    z-index: 10;
    padding: $spacing * 0.75;
    background: $c-pink-medium;
    color: white;
    font-size: 1.4rem;
    line-height: 1.2;
    text-align: center;
    border-radius: 8px;

    a {
      color: $white;
      font-family: $ff-alt;
    }

    strong {
      display: block;
    }

    &::before {
      @include center-y;
      content: '';
      top: 50%;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: $c-pink-medium;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .icon {
      margin-bottom: $spacing / 2;
      fill: $white;
    }

    @include mq(s) {
      top: 6rem;
    }

    @include mq($until: l) {
      bottom: -7rem;
      top: auto;
      left: 50%;
      // right: auto;
      transform: translateX(-50%) scale(0);
      animation: tooltip 0.25s 1.5s forwards
        cubic-bezier(0.175, 0.885, 0.32, 1.275);

      &::before {
        top: 0;
        right: initial;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    @include mq(l) {
      top: 2rem;
      bottom: auto;
      right: 0;
      left: auto;
      font-size: 1.8rem;
      transform: translateX(115%) scale(0);
      animation: tooltipL 0.25s 1.5s forwards
        cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  @keyframes tooltip {
    from {
      transform: translate(-50%, 50%) scale(0);
    }

    to {
      transform: translate(-50%, 50%) scale(1);
    }
  }

  @keyframes tooltipL {
    from {
      transform: translate(115%, -50%) scale(0);
    }

    to {
      transform: translate(115%, -50%) scale(1);
    }
  }

  .anchor__switcher {
    display: none;
  }

  .anchor__switcher__item.is-current {
    .anchor__switcher__item__link {
      position: relative;

      @include mq(l) {
        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: -10%;
          left: 0;
          display: block;
          width: 100%;
          height: 120%;
          background-color: $c-anthracite-light;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .anchor__switcher__item.is-active {
    .anchor__switcher__item__link {
      color: $white;

      @include mq($until: l) {
        color: $c-blue-dark !important;
      }
    }
  }

  @include mq($until: l) {
    padding-bottom: $spacing * 5 !important;
  }
}

.mobile-network-comparatif__items {
  position: relative;
  max-width: 85rem;
  margin: 0 auto;
  margin-top: $spacing * 3;
  border: 1px solid $c-gray-light;
  border-radius: 0.5rem;
}

.mobile-network-comparatif__item {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $spacing;
  opacity: 0;

  &:first-child {
    opacity: 1;
  }

  @include mq(m) {
    padding: $spacing * 2 $spacing * 3;
  }
}

.mobile-network-comparatif__item__title {
  margin-top: 0;
  margin-bottom: $spacing * 2;
  font-size: 2.6rem;
  font-weight: 100;
  text-align: center;
}

.mobile-network-comparatif__item__company {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: $spacing * 1.5;
  }

  @include mq($until: l) {
    // position: static;
  }
}

.mobile-network-comparatif__item__company__logo {
  width: 15%;
}

.mobile-network-comparatif__item__company__progress {
  width: 65%;
  max-width: 400rem;
  height: 1.4rem;
  background-color: $c-gray-lightest;
  background-image: linear-gradient(
    to left,
    $c-gray-lightest 50%,
    $c-pink-medium 50%,
    $c-pink-medium 100%
  );
  background-size: 200%;
  background-position-x: (100%);
  border-radius: 5rem;

  .proximus & {
    background-image: linear-gradient(
      to left,
      $c-gray-lightest 50%,
      $c-proximus 50%,
      $c-proximus 100%
    );
  }

  .orange & {
    background-image: linear-gradient(
      to left,
      $c-gray-lightest 50%,
      $c-orange-company 50%,
      $c-orange-company 100%
    );
  }

  @include mq($until: l) {
    order: 3;
    width: 100%;
  }
}

.mobile-network-comparatif__item__company__label {
  display: inline-flex;
  justify-content: flex-end;
  width: 15%;
  color: $c-gray-light;
  font-size: 1.8rem;
}
